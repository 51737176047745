import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const getCardsData = async () => {
  try {
    return await fetchWithCompanyId('/ip/cards', {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getFilteredCardsData = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/cards?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching filtered cards data: ' + error.message);
  }
};

export const getCardDetailsData = async (guid: any) => {
  try {
    return await fetchWithCompanyId(`/ip/details/${guid}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching card details data: ' + error.message);
  }
};

export const cardImageUrlGeneration = async (fileName: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/media/image-urls?name=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};

export const detailsImageUrlGeneration = async (fileName: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/media/image-urls?name=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};
