import * as XLSX from 'xlsx';

/**
 * Flattens the IntellectualProperty data for Excel export.
 */
export const flattenData = (data: any[]): Record<string, string>[] => {
  return data
    .filter((item) => item.card && Object.keys(item.card).length > 0) // Skip items with empty or missing card
    .map((item) => ({
      ipMetadataGuid: item.ipMetadataGuid,
      Title: item.card.title || 'No Title',
      Subtitle: item.card.subtitle || 'No Subtitle',
      Status: item.card.status || 'No Status',
      Description: item.card.description || 'No Description',
      Events: item.card.event
        ? item.card.event
            .map((event: any) => `${event.description}: ${event.date}`)
            .join('\r\n')
        : 'No Events',
    }));
};

/**
 * Generates and triggers an Excel file download using SheetJS.
 */
export const generateExcel = (data: any[], fileName = 'IP_Data.xlsx') => {
  const flattenedData = flattenData(data);
  const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'IP Data');

  worksheet['!cols'] = [
    { wch: 30 },
    { wch: 30 }, // Title Column Width
    { wch: 30 },
    { wch: 20 },
    { wch: 30 },
    { wch: 40 },
  ];
  // Force text wrapping and apply grayish-purple coloring for both Excel and Google Sheets
  const headerRange = XLSX.utils.decode_range(worksheet['!ref']!);
  for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: C });
    if (!worksheet[headerCell]) continue;

    // Apply the header styles
    worksheet[headerCell].s = {
      font: { bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: 'B3A0C2' } }, // Grayish purple
      alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
    };
  }

  // Force text wrapping for the entire worksheet
  Object.keys(worksheet).forEach((cell) => {
    if (
      worksheet[cell] &&
      typeof worksheet[cell].v === 'string' &&
      worksheet[cell].v.includes('\r\n')
    ) {
      worksheet[cell].s = {
        alignment: { wrapText: true },
      };
    }
  });

  // Save the workbook with styling
  XLSX.writeFile(workbook, fileName);
};
