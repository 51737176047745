import React from 'react';
import { Button, Card, Dropdown, Menu, notification, List } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CostCardIcon } from '../assets/icons/costCardIcon.svg';
import { ReactComponent as FilesCardIcon } from '../assets/icons/filesCardIcon.svg';
import { ReactComponent as MoreOptionsIcon } from '../assets/icons/moreOptions.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/configureStore';
import { downloadFile } from '../services/filesService/filesService.service';

interface DataCardProps {
  data: any;
  isDarkMode: boolean;
  isFiles?: boolean;
  fileName?: string;
  onMarkAsRead?: (fileName: string) => void;
}

const StyledCard = styled(Card)`
  height: 46px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .ant-card-body {
    width: -webkit-fill-available;
  }
`;

const DataCard: React.FC<DataCardProps> = ({
  data,
  isDarkMode,
  isFiles,
  onMarkAsRead,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const date = isFiles ? data.date : data.costDate;
  const fileName = isFiles ? data.filepath.split('/').pop() : data.description;
  const fileDescription = isFiles
    ? 'Some file description, utill we have the updated fileJSON '
    : null;

  const formattedDate = new Intl.DateTimeFormat('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));

  const handleDownloadClick = async () => {
    try {
      const response = await downloadFile(data.filenameNew);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName); //TODO change the diwnloadable file name asa fifileJSON available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (onMarkAsRead) onMarkAsRead(data.guid);
    } catch (error: any) {
      notification.error({
        message: 'Download failed',
        description: error.message || 'An error occurred during download.',
      });
    }
  };

  return (
    <StyledCard
      style={{
        background: isDarkMode ? '#2B2B39' : '#F5F5F5',
        border: '1px solid #6C729D',
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        // maxWidth: isFiles ? '850px' : 'none',
        ...(isFiles
          ? { height: '60px' } // Only apply height style for files
          : { height: '46px' }),
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexBasis: isFiles ? '70%' : '40%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              width: '24px',
              height: '24px',
              minWidth: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isFiles ? (
              <FilesCardIcon style={{ width: '100%', height: '100%' }} />
            ) : (
              <CostCardIcon style={{ width: '100%', height: '100%' }} />
            )}
          </div>
          <div
            style={{
              marginLeft: '8px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <span
              style={{
                color: '#A3A7C3',
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {fileName}
            </span>
            {fileDescription && (
              <span
                style={{
                  color: '#656A92',
                  // fontSize: '12px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {fileDescription}
              </span>
            )}
          </div>
        </div>
        {/* Invoice Number or File Identifier */}
        <div
          style={{
            flexBasis: '20%',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <p style={{ color: '#656A92', margin: 0 }}>
            <strong>{!isFiles && data.invoiceNumber}</strong>
          </p>
        </div>

        {/* Date */}
        <div style={{ flexBasis: '20%', textAlign: 'center' }}>
          <p style={{ color: '#656A92', margin: 0 }}>
            <strong>{formattedDate}</strong>
          </p>
        </div>

        {/* Amount Display for Costs Only */}
        {!isFiles && (
          <div
            style={{
              flexBasis: '15%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              textWrap: 'nowrap',
            }}
          >
            <p style={{ color: '#8589C1', margin: 0 }}>
              <strong>{data.amount.toFixed(2)}€</strong>
            </p>
          </div>
        )}

        {/* Download Button for Files */}
        {isFiles ? (
          <div style={{ flexBasis: '10%', textAlign: 'right' }}>
            <Button type='link' onClick={handleDownloadClick}>
              Download
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexBasis: '5%',
              alignItems: 'center',
            }}
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>No actions</Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                shape='circle'
                icon={<MoreOptionsIcon />}
                ghost
                style={{ border: 'none', width: '24px', height: '24px' }}
              />
            </Dropdown>
          </div>
        )}
      </div>
    </StyledCard>
  );
};

export default DataCard;
