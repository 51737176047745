import React, { useEffect, useState } from 'react';
import { theme, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { setFileData } from '../../store/ducks/File/operations';
import { File } from '../../store/ducks/File/types';
import GroupedCards from '../../components/GroupedCards';
import {
  getAllFilesData,
  getFilteredFilesData,
  markAllAsRead,
  markAsRead,
} from '../../services/filesService/filesService.service';
import { useLocation } from 'react-router-dom';

const FileComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const selectedCompany = useSelector(
    (state: RootState) => state.global.selectedCompany
  );
  const [filesData, setFilesData] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '65%',
    minWidth: 'fit-content',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    display: 'grid',
    gap: '26px',
    height: '100%',
  };

  useEffect(() => {
    dispatch(
      setPageInfo({ currentPageName: 'Files', numberOfElem: filesData.length })
    );
  }, [dispatch, filesData.length]);

  useEffect(() => {
    dispatch(setFileData(filesData));
  }, [dispatch, filesData]);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const filter = params.get('filter');
      const queryParams = new URLSearchParams();

      if (filter) {
        queryParams.append('ipMetadataGuIds', filter);
      }

      const queryString = queryParams.toString();

      const data: File[] = filter
        ? await getFilteredFilesData(queryString)
        : await getAllFilesData();

      const sortedData = data.sort((a: File, b: File) => b.date - a.date);
      setFilesData(sortedData);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to get files data',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [location.search, selectedCompany]);

  const onMarkAllAsRead = async () => {
    setLoading(true);
    try {
      await markAllAsRead();
      await fetchFiles(); // Refetch files after marking all as read
      notification.success({
        message: 'Success',
        description: 'All files marked as read.',
      });
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark all files as read',
      });
    }
  };

  const onMarkAsRead = async (guid: string) => {
    setLoading(true);
    try {
      await markAsRead(guid);
      await fetchFiles(); // Refetch files after marking a single file as read
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark file as read',
      });
    }
  };

  const groupData = (data: File[]) => {
    const unreadFiles = data
      .filter((file) => !file.isRead)
      .sort((a, b) => b.date - a.date);
    const readFiles = data.filter((file) => file.isRead);

    const groupedReadFiles = readFiles.reduce(
      (acc: Record<string, File[]>, file) => {
        const year = new Date(file.date).getFullYear().toString();
        if (!acc[year]) acc[year] = [];
        acc[year].push(file);
        return acc;
      },
      {}
    );

    return { unreadFiles, groupedReadFiles };
  };

  const { unreadFiles, groupedReadFiles } = groupData(filesData);

  return (
    <div style={contentStyle}>
      {loading ? (
        <Spin tip='Loading...' size='large' style={{ margin: 'auto' }} />
      ) : (
        <GroupedCards
          unreadFiles={unreadFiles}
          groupedReadFiles={groupedReadFiles}
          isDarkMode={isDarkMode}
          isFiles={true}
          onMarkAllAsRead={onMarkAllAsRead}
          onMarkAsRead={onMarkAsRead}
        />
      )}
    </div>
  );
};

export default FileComponent;
