import React, { useEffect, useState, useCallback } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { theme } from 'antd';
import ChoroplethMap from '../../components/ChoroplethMap';
import {
  getCostsPerJurisdiction,
  getFileTypeCounts,
  getJurisdictionCounts,
  getCountryCoverage,
  getPublicationCounts,
  getTopCompanies,
  getTopInventors,
} from '../../services/insights/insights.service';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

const Insights: React.FC = () => {
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '100%',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-around',
    rowGap: '40px',
    columnGap: '80px',
  };

  const dispatch = useDispatch<AppDispatch>();
  const [countryCoverage, setCountryCoverage] = useState<any[]>([]);
  const [jurisdictionCounts, setJurisdictionCounts] = useState<any[]>([]);
  const [fileTypes, setFileTypes] = useState<any[]>([]);
  const [publications, setPublications] = useState<any[]>([]);
  const [topCompanies, setTopCompanies] = useState<any[]>([]);
  const [topInventors, setTopInventors] = useState<any[]>([]);
  const [costsPerJurisdiction, setCostsPerJurisdiction] = useState<any[]>([]);
  const selectedCompany = useSelector(
    (state: RootState) => state.global.selectedCompany
  );
  const [loadingCountryCoverage, setLoadingCountryCoverage] = useState(true);
  const [loadingJurisdictionCounts, setLoadingJurisdictionCounts] =
    useState(true);
  const [loadingFileTypes, setLoadingFileTypes] = useState(true);
  const [loadingPublications, setLoadingPublications] = useState(true);
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);
  const [loadingTopInventors, setLoadingTopInventors] = useState(true);
  const [loadingCostsPerJurisdiction, setLoadingCostsPerJurisdiction] =
    useState(true);

  const { searchTerm } =
    useSelector((state: RootState) => state.iProperty) || {};

  console.log('searchTerm', searchTerm);
  const sortPublications = (data: any[]) => {
    return data.sort((a, b) => {
      const yearA = a.groupName ? parseInt(a.groupName) : 0;
      const yearB = b.groupName ? parseInt(b.groupName) : 0;
      return yearA - yearB;
    });
  };

  const fetchData = useCallback(async () => {
    setLoadingCountryCoverage(true);
    setLoadingJurisdictionCounts(true);
    setLoadingFileTypes(true);
    setLoadingPublications(true);
    setLoadingTopCompanies(true);
    setLoadingTopInventors(true);
    setLoadingCostsPerJurisdiction(true);

    const queryParams = new URLSearchParams();
    if (searchTerm) {
      queryParams.append('search', searchTerm);
    }
    const queryString = queryParams.toString();

    // Fetch and update each widget individually
    try {
      const fetchCountryCoverage = async () => {
        const fetchedCountryCoverage = await getCountryCoverage(queryString);
        setCountryCoverage(fetchedCountryCoverage);
      };

      const fetchJurisdictionCounts = async () => {
        const fetchedJurisdictionCounts = await getJurisdictionCounts(
          queryString
        );
        setJurisdictionCounts(fetchedJurisdictionCounts);
      };

      const fetchFileTypes = async () => {
        const fetchedFileTypes = await getFileTypeCounts(queryString);
        setFileTypes(fetchedFileTypes);
      };

      const fetchPublications = async () => {
        const fetchedPublications = await getPublicationCounts(queryString);
        const sortedPublications = sortPublications(fetchedPublications);
        setPublications(sortedPublications);
      };

      const fetchTopCompanies = async () => {
        const fetchedTopCompanies = await getTopCompanies(queryString);
        setTopCompanies(fetchedTopCompanies);
      };

      const fetchTopInventors = async () => {
        const fetchedTopInventors = await getTopInventors(queryString);
        setTopInventors(fetchedTopInventors);
      };

      const fetchCostsPerJurisdiction = async () => {
        const fetchedCostsPerJurisdiction = await getCostsPerJurisdiction(
          queryString
        );
        setCostsPerJurisdiction(fetchedCostsPerJurisdiction);
      };

      // Fetch each widget independently
      await Promise.allSettled([
        fetchCountryCoverage().finally(() => setLoadingCountryCoverage(false)),
        fetchJurisdictionCounts().finally(() =>
          setLoadingJurisdictionCounts(false)
        ),
        fetchFileTypes().finally(() => setLoadingFileTypes(false)),
        fetchPublications().finally(() => setLoadingPublications(false)),
        fetchTopCompanies().finally(() => setLoadingTopCompanies(false)),
        fetchTopInventors().finally(() => setLoadingTopInventors(false)),
        fetchCostsPerJurisdiction().finally(() =>
          setLoadingCostsPerJurisdiction(false)
        ),
      ]);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description:
          error.response?.data?.message || error.message || error.toString(),
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedCompany]);

  return (
    <div style={contentStyle}>
      <ChoroplethMap data={countryCoverage} loading={loadingCountryCoverage} />
      <BarChart
        title='Jurisdiction'
        data={jurisdictionCounts}
        isApplications={false}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingJurisdictionCounts}
      />
      <BarChart
        title='Applications over time'
        data={publications}
        isApplications={true}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingPublications}
      />
      <PieChart
        data={fileTypes}
        isCosts={false}
        title='IP assets'
        loading={loadingFileTypes}
      />
      <PieChart
        data={costsPerJurisdiction}
        isCosts={true}
        title='Costs per jurisdiction'
        loading={loadingCostsPerJurisdiction}
      />
      <BarChart
        title='IP assets per company'
        data={topCompanies}
        isApplications={false}
        isVertical={false} // Set to horizontal
        iscomp={true}
        isInv={false}
        loading={loadingTopCompanies}
      />
      <BarChart
        title='IP assets per inventor'
        data={topInventors}
        isApplications={false}
        isVertical={false} // Set to horizontal
        iscomp={false}
        isInv={true}
        loading={loadingTopInventors}
      />
    </div>
  );
};

export default Insights;
