import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, Spin, Empty } from 'antd';

interface BarChartProps {
  isVertical: boolean;
  iscomp: boolean;
  isInv: boolean;
  data: any;
  title: string;
  loading: boolean;
  isApplications: boolean;
}

const BarChart = ({
  isVertical,
  iscomp,
  isInv,
  data,
  title,
  loading,
  isApplications,
}: BarChartProps) => {
  const hasEmptyGroupName =
    data.length === 1 && !data[0].groupName?.toString().trim();

  const validData = data.filter(
    (item: any) => item.groupName && item.groupName.toString().trim() !== ''
  );

  const aggregateData = (data: any[]) => {
    return data.reduce((acc, item) => {
      const existing = acc.find((i: any) => i.groupName === item.groupName);
      if (existing) {
        existing.count += item.count;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, [] as { groupName: string; count: number }[]);
  };

  const addMissingYears = (data: any[]) => {
    console.log('Data:', data);

    if (data.length === 0) return data;

    // Filter out items with null groupName
    const filteredData = data.filter((item) => item.groupName !== null);

    // Extract years as numbers for range calculation
    const years = filteredData.map((item) => parseInt(item.groupName));
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);

    // Generate full range of years as strings
    const fullYearRange = Array.from(
      { length: maxYear - minYear + 1 },
      (_, i) => (minYear + i).toString()
    );

    // Fill in missing years with a count of 0
    const filledData = fullYearRange.map((year) => {
      const existing = filteredData.find((item) => item.groupName === year);
      return existing ? existing : { groupName: year, count: 0 };
    });

    console.log('Filled Data:', filledData);
    return filledData;
  };

  const aggregatedData = aggregateData(validData);

  const sortedData =
    title === 'Applications over time'
      ? addMissingYears(aggregatedData)
      : title === 'Jurisdiction'
      ? aggregatedData
          .sort(
            (a: { count: number }, b: { count: number }) => b.count - a.count
          ) // Sort by count descending
          .slice(0, 10) // Limit to top 10 records
      : aggregatedData.sort(
          (a: { count: number }, b: { count: number }) => b.count - a.count
        ); // Default sorting for other cases

  const customTooltip = ({ indexValue, value }: any) => {
    // Use the full groupName from the data directly for the tooltip
    const fullLabel = sortedData.find((item: any) =>
      item.groupName.startsWith(indexValue.replace('...', ''))
    )?.groupName;

    return (
      <div
        style={{
          padding: '5px 10px',
          background: 'white',
          border: '1px solid #ccc',
          borderRadius: '3px',
          color: '#000',
        }}
      >
        {fullLabel || indexValue}: <strong>{value} cases</strong>
      </div>
    );
  };

  return (
    <Card
      title={title}
      style={{ width: '500px', height: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          height: '400px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Spin size='large' />
        ) : data.length === 0 ? (
          <Empty description='No data to display' />
        ) : (
          <ResponsiveBar
            data={sortedData}
            keys={['count']}
            colors={isApplications ? '#66C2A5' : { scheme: 'set2' }}
            indexBy={(d) =>
              d.groupName.toString().length > 20
                ? `${d.groupName.toString().slice(0, 20)}...`
                : d.groupName.toString()
            }
            margin={{
              top: 20,
              right: 30,
              bottom: isVertical ? 80 : 50, // Increase for vertical
              left: isVertical ? 50 : hasEmptyGroupName ? 20 : 150, // Adjust space dynamically
            }}
            padding={0.3}
            layout={isVertical ? 'vertical' : 'horizontal'}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colorBy='indexValue'
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10, // Reduce font size
                    overflow: 'hidden', // Prevent text overflow
                    textOverflow: 'ellipsis', // Add ellipsis if the text overflows
                    whiteSpace: 'nowrap', //
                  },
                },
              },
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: isVertical ? -90 : 0,
              legendPosition: 'middle',
              legendOffset: 50,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -60,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
            tooltip={customTooltip}
          />
        )}
      </div>
    </Card>
  );
};

export default BarChart;
