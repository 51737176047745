export const fetchWithCompanyId = async (
  url: string,
  options?: RequestInit
) => {
  const selectedCompany = localStorage.getItem('selectedCompany') || '';
  const urlObj = new URL(url, 'https://api.ipmanagement.io'); // Base URL for API
  if (selectedCompany) {
    urlObj.searchParams.append('companyGuId', selectedCompany); // Append companyGuId
  }

  const updatedUrl = urlObj.toString();

  const response = await fetch(updatedUrl, options);

  if (!response.ok) {
    throw new Error(`Fetch error: ${response.statusText}`);
  }

  return response.json();
};
