import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const getJurisdictionsList = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/jurisdictions?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching jurisdictions list: ' + error.message);
  }
};

export const getJurisdictionCounts = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/counts/jurisdiction?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching jurisdiction counts: ' + error.message);
  }
};

export const getFileTypeCounts = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/counts/fileType?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching file type counts: ' + error.message);
  }
};

export const getPublicationCounts = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/counts/dateFiled?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching publication counts: ' + error.message);
  }
};

export const getTopCompanies = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/counts/applicant?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching top companies: ' + error.message);
  }
};

export const getTopInventors = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/counts/inventor?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching top inventors: ' + error.message);
  }
};

export const getCostsPerJurisdiction = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/cost/per-jurisdiction?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching costs per jurisdiction: ' + error.message);
  }
};

export const getCountryCoverage = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(
      `/ip/counts/countryCoverage?${queryString}`,
      {
        headers: authHeader(),
      }
    );
  } catch (error: any) {
    throw new Error('Error fetching costs per jurisdiction: ' + error.message);
  }
};
